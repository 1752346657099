import { ColDef } from 'ag-grid-enterprise';
import { alignLeft, comparator, component, mergeClasses } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { h } from 'vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { DepthOfBookItem } from '@/modules/marketplace/models';

type ItemBorrow = DepthOfBookItem['borrow'];
type ItemLend = DepthOfBookItem['lend'];

export function borrowCounterparty(): ColDef<DepthOfBookItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowCounterparty',
    headerName: 'Counterparty',
    valueFormatter: (params) => (!params.value ? '–' : params.value.counterparty.companyName),
    ...alignLeft(),
  };
}

export function borrowQuantity(): ColDef<DepthOfBookItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowQuantity',
    headerName: 'Quantity',
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value.quantity)),
    comparator(a, b) {
      return comparator.number(a?.quantity, b?.quantity);
    },
    ...alignLeft(),
  };
}

const independentAmountRateAdapter = component<{
  item?: ItemBorrow | ItemLend;
}>(
  (props) => () =>
    !props.item
      ? h('span', '–')
      : h(RateOutput, { props: { rate: props.item.independentAmountRate, precision: 2 } })
);

export function borrowIndependentAmountRate(): ColDef<DepthOfBookItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowIndependentAmountRate',
    headerName: 'IA Limit',
    cellRendererSelector: (params) =>
      independentAmountRateAdapter({
        item: params.value,
      }),
    comparator(a, b) {
      return comparator.decimal(a?.independentAmountRate, b?.independentAmountRate);
    },
    ...alignLeft(),
  };
}

const rateAdapter = component<{
  item?: ItemBorrow | ItemLend;
}>((props) => () => (!props.item ? h('span', '–') : h(RateOutput, { props: props.item })));

export function borrowRate(): ColDef<DepthOfBookItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowRate',
    headerName: 'Bid',
    cellRendererSelector: (params) =>
      rateAdapter({
        item: params.value,
      }),
    comparator(a, b) {
      return comparator.decimal(a?.rate, b?.rate);
    },
    cellDataType: 'text',
    ...mergeClasses([alignLeft(), { cellClass: 'highlighted-border-right' }]),
  };
}

export function lendRate(): ColDef<DepthOfBookItem, ItemLend> {
  return {
    field: 'lend',
    colId: 'lendRate',
    headerName: 'Ask',
    cellRendererSelector: (params) =>
      rateAdapter({
        item: params.value,
      }),
    comparator(a, b) {
      return comparator.decimal(a?.rate, b?.rate);
    },
    cellDataType: 'text',
    ...alignLeft(),
  };
}

export function lendIndependentAmountRate(): ColDef<DepthOfBookItem, ItemLend> {
  return {
    field: 'lend',
    colId: 'lendIndependentAmountRate',
    headerName: 'IA',
    cellRendererSelector: (params) =>
      independentAmountRateAdapter({
        item: params.value,
      }),
    comparator(a, b) {
      return comparator.decimal(a?.independentAmountRate, b?.independentAmountRate);
    },
    ...alignLeft(),
  };
}

export function lendQuantity(): ColDef<DepthOfBookItem, ItemLend> {
  return {
    field: 'lend',
    colId: 'lendQuantity',
    headerName: 'Quantity',
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value.quantity)),
    comparator(a, b) {
      return comparator.number(a?.quantity, b?.quantity);
    },
    ...alignLeft(),
  };
}

export function lendCounterparty(): ColDef<DepthOfBookItem, ItemLend> {
  return {
    field: 'lend',
    colId: 'lendCounterparty',
    headerName: 'Counterparty',
    valueFormatter: (params) => (!params.value ? '–' : params.value.counterparty.companyName),
    ...alignLeft(),
  };
}
